:root {
    --main-color: #fff;
    --main-color-alpha-min: #ffffff80;
    --main-color-alpha-max: #ffffff50;
    --main-color-inverted: #000;
    --main-color-inverted-alpha: #00000015;
  }
body {
    @media (prefers-color-scheme: dark) {
        --main-color: #000;
        --main-color-alpha-min: #00000080;
        --main-color-alpha-max: #00000050;
        --main-color-inverted: #fff;
        --main-color-inverted-alpha: #ffffff35;
    }
    &.--dark-mode{
        --main-color: #000 !important;
        --main-color-alpha-min: #00000080 !important;
        --main-color-alpha-max: #00000050 !important;
        --main-color-inverted: #fff !important;
        --main-color-inverted-alpha: #ffffff35 !important;
    }
    &.--light-mode {
        --main-color: #fff !important;
        --main-color-alpha-min: #ffffff80 !important;
        --main-color-alpha-max: #ffffff50 !important;
        --main-color-inverted: #000 !important;
        --main-color-inverted-alpha: #00000015 !important;
    }
    font-family: "Anonymous Pro", monospace;
    font-size: 16px;
    min-height: calc(100vh - 11rem);
    margin:0;
    background-color: var(--main-color);
    color: var(--main-color-inverted);
    transition: background-color .12s cubic-bezier(0.445, 0.05, 0.55, 0.95), color .2s ease-out;
    .nav {
        color: var(--main-color);
        background-color: var(--main-color-inverted);
        padding: .4rem 0;
        transition: padding .3s ease-out;
        top: 0;
        z-index: 99;
        position: sticky;
        @media screen and (max-width: 50rem){
            padding: 1rem 0;
            .container .links {
                display: none;
            }
        }
        .container {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            position: relative;
            .links {
                width: 4rem;
                position: absolute;
                display: flex;
                flex-direction: row;
                left: calc(50% - 2rem);
                @media screen and (max-width: 50rem){
                    display: none;
                }
                .twitter,
                .linkedin {
                    height: 1rem;
                    width:1rem;
                    margin: 0 .5rem;
                    opacity: .7;
                    .a {
                        fill: var(--main-color);
                    }
                    .b {
                        fill: var(--main-color-inverted);
                    }
                    &:hover {
                        opacity: 1;
                    }
                    svg {
                        vertical-align: middle;
                    }
                }
            }
            .button {
                &.switch {
                    margin-left: 1rem;
                    height: 1.2rem;
                    width: 1.2rem;
                    &:active,
                    &:focus {
                        transform: scale(1.1);
                        transition: transform .1s ease-out;
                    }
                    #moon {
                        max-width: 100%;
                        fill: var(--main-color-inverted);
                        stroke: var(--main-color);
                        stroke-width: .5rem;
                        transition: fill .2s ease-in;
                        transform-origin: center;
                    }
                    &.on{
                        #moon {
                        fill: var(--main-color);
                        stroke: var(--main-color-inverted);
                        stroke-width: .3rem;
                        }
                    }
                }
            }
        }
    }
    .hero {
        padding-bottom: 3rem;
        align-self: flex-start;
        max-width: 77rem;
        margin: 0 auto;
        user-select: none;
        @media screen and (max-width: 60rem){
            max-width: 47rem;
        }
        @media screen and (max-width: 40rem){
            max-width: 57rem;
        }
        h1 {
            font-size: 4rem;
            margin-bottom: 1rem;

            @media screen and (max-width: 40rem) {
                font-size: 2rem;
            }
        }
    }
    .grid {
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(4, 18rem);
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        @media screen and (max-width: 80rem) {
            grid-template-columns: repeat(3, 18rem);
        }
        @media screen and (max-width: 60rem) {
            grid-template-columns: repeat(2, 18rem);
        }
        @media screen and (max-width: 40rem) {
            grid-template-columns: repeat(1, 1fr);
            .project {
                margin-left: auto;
                margin-right: auto;
            }
        }
        padding-bottom: 16rem;

        .project {
            box-sizing: border-box;
            display: block;
            border: .3rem solid var(--main-color-inverted-alpha);
            font-family: "Anonymous Pro", monospace;
            width: 18rem;
            box-shadow: 0 .2rem .5rem var(--main-color-inverted-alpha);
            position: relative;
            border-radius: .9rem;
            transition: transform .3s ease-in-out, filter .2s ease-in;
            filter: grayscale(1);
            @media screen and (max-width: 20rem){
                width: unset;
                margin: .5rem;
            }
            &.active,
            &:focus,
            &:hover {
                outline: 0;
                transform: scale(1.02);
                animation: messmer infinite alternate 1s cubic-bezier(0.46, 0.03, 0.52, 0.96);
                filter: grayscale(0);
            }
            
            .illustration {
                background-size: cover;
                background-position: center;
                border-radius: .6rem;
                height: 12rem;
                &.blur {
                    position: absolute;
                    top: -.5rem;
                    left: -.5rem;
                    height: 13rem;
                    filter: blur(.6rem);
                    width: calc(100% + 1rem);
                    opacity: 0;
                    z-index: -1;
                    transition: opacity .2s ease-in-out;
                }
            }
            .label {      
                overflow-y: hidden;
                transition: all .2s ease-in;
                height: 5rem;
                padding: .5rem 1rem;
                border-bottom-left-radius: .6rem;
                border-bottom-right-radius: .6rem;
                backdrop-filter: blur(4px);
                -webkit-backdrop-filter: blur(4px);
                -moz-backdrop-filter: blur(4px);
                background-color: var(--main-color-alpha-min);
                position: absolute;
                border: 1px solid transparent;
                width: 100%;
                bottom: -1px;
                left: 0;
                box-sizing: border-box;
                transition: height .2s ease-in-out, bottom .2s ease-in-out, border-radius .2s ease-in-out;
                &::before {
                    transition: width .2s cubic-bezier(0.215, 0.610, 0.355, 1);
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: .3rem;
                    background-color: var(--main-color-inverted-alpha);
                    width: 0;
                }

                .title {
                    margin-top: .4rem;
                    font-size: 1.3rem;
                }
                
                .muted {
                    opacity: .6;
                    font-size: 1rem;
                }

                .tags {
                    margin: .3rem 0;
                    font-size: .7rem;
                    .tag {
                        position: relative;
                        top: .5rem;
                        opacity: 0;
                        border-radius: .2rem;
                        padding: .2rem .4rem;
                        background-color: var(--main-color-inverted-alpha);
                        display: inline-block;
                        @for $i from 1 to 6 {
                            &:nth-child(#{$i}){ animation-delay: #{$i/10}s;}
                        }
                    }
                    @keyframes appear {
                        from {
                            opacity: 0;
                        }
                        to {
                            transform: translateY(-.5rem);
                            opacity: .7;
                        }
                    }
                }
            }
            &.active,
            &:focus,
            &:hover {
                .label {
                    height: 6rem;
                    transform: translateY(-1rem);
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    transition: all .25s .15s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                    &:before {
                        width:100%;
                        transition: width .2s .4s cubic-bezier(0.55, 0.055, 0.675, 0.19);
                    }
                    .tag {
                        animation: appear .2s ease-in-out forwards;
                    }
                }
                .illustration.blur{
                    opacity: .5;
                }
            }
        }
    }
    a {
        color: inherit;
    }
    @keyframes messmer {
        0% {
            transform: translateX(0) translatey(0);
        }
        100% {
            transform: translateX(0) translatey(-.2rem) scale(1.03);
        }
    }
    .container {
        max-width: 77rem;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width: 80rem){
            max-width: 58rem;
        }
        @media screen and (max-width: 60rem){
            max-width: 38rem;
        }
        @media screen and (max-width: 50rem) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        @media screen and (max-width: 30rem) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
